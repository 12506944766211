import React from 'react'
import { createRoot } from 'react-dom/client'

import 'assets/styles/index.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'simplebar-react/dist/simplebar.min.css'

import 'translations'

import App from 'App'
import { LicenseInfo } from '@mui/x-license'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-PB1DEXYHE5')
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY)
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/.*\.keyhowl\.com/],
  // Session Replay
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_FRONT_END_APP_URL,
})

createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)
